<template>
  <div class="about">
    <h1>This is an testtest page</h1>
  </div>
</template>

<script setup>
import ApiNft from "@/apis";

const getList = () => {
  ApiNft.getList();
};

getList();
</script>

<style lang="scss" scoped>
h1 {
  font-size: 24rpx;
}
</style>
